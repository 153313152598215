import React, { useState, useEffect, useRef } from 'react';
import RoleTextAnimation from './Javascript/RoleTextAnimation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import './LandingZone.css';

const LandingZone = () => {
  const [showArrow, setShowArrow] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const landingZoneRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const landingZone = landingZoneRef.current;
      if (landingZone) {
        const landingZoneRect = landingZone.getBoundingClientRect();
        const landingZoneBottom = landingZoneRect.bottom;
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY || window.pageYOffset;
        const maxScrollPositionToShowArrow = windowHeight / 2;
        setShowArrow(landingZoneBottom > maxScrollPositionToShowArrow && scrollPosition === 0);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section ref={landingZoneRef} className="flex-1 h-screen bg-neutral-900">
      <div className="h-screen mx-10 flex flex-col items-center justify-center">
        {/* Content inside the first section */}
        <div className="w-full max-w-7xl -mt-20">

          <div className="title">
            <span className="block"></span>
            <h1>Frederik EAS<span></span></h1>
          </div>
          <div className="role">
            <div className="block"></div>
            <p><RoleTextAnimation /></p>
          </div>

        </div>

        {/* Arrow down */}
        <a
          href="#aboutme"
          className={`absolute bottom-0 mb-8 rounded-full p-3 border-4 border-neutral-600 animate-bounce transition-opacity duration-300 focus:bg-neutral-600 focus:border-yellow-600 ${showArrow ? 'opacity-100' : 'opacity-0'} ${
            isMobile ? '' : 'hover:bg-neutral-600 hover:border-yellow-600'
          }`}
          onClick={() => {
            if (isMobile) {
              // Toggle hover effect on mobile
              const link = document.querySelector('a[href="#aboutme"]');
              link.classList.toggle('bg-neutral-600');
              link.classList.toggle('border-yellow-600');
            }
          }}
        >
          <FontAwesomeIcon icon={faArrowDown} className="text-yellow-800 text-4xl" />
        </a>
      </div>
    </section>
  );
}

export default LandingZone;
