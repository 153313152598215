import React, { useRef, useEffect } from 'react';

const Modal = ({ project, closeModal }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 overflow-y-auto">
      <div ref={modalRef} className="bg-white text-black rounded-lg shadow-xl max-w-full md:max-w-2xl w-full h-full md:h-auto overflow-y-auto md:rounded-lg p-4">
        <div className="flex justify-between items-center border-b border-gray-300 pb-2">
          <h2 className="text-xl font-bold">{project.title}</h2>
          <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
            &times;
          </button>
        </div>
        <div className="py-4">
          <p>{project.fullDescription}</p>
          {project.imageUrl && (
            <img src={project.imageUrl} alt={project.title} className="mt-4 w-full rounded" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
