import React from 'react';

const BlockGradientUp = () => {
    return (
        <section>
            {/* Wave pattern */}
            <div className="bg-gray-200 -m-1 flex flex-col md:flex-row mx-auto">
                <div className="w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#1C1917" viewBox="0 0 1440 320"><path  d="M0,192L0,160L38.9,160L38.9,0L77.8,0L77.8,160L116.8,160L116.8,288L155.7,288L155.7,96L194.6,96L194.6,128L233.5,128L233.5,288L272.4,288L272.4,32L311.4,32L311.4,224L350.3,224L350.3,64L389.2,64L389.2,96L428.1,96L428.1,224L467,224L467,288L505.9,288L505.9,288L544.9,288L544.9,128L583.8,128L583.8,288L622.7,288L622.7,32L661.6,32L661.6,96L700.5,96L700.5,224L739.5,224L739.5,128L778.4,128L778.4,256L817.3,256L817.3,96L856.2,96L856.2,64L895.1,64L895.1,288L934.1,288L934.1,160L973,160L973,0L1011.9,0L1011.9,160L1050.8,160L1050.8,224L1089.7,224L1089.7,256L1128.6,256L1128.6,256L1167.6,256L1167.6,32L1206.5,32L1206.5,256L1245.4,256L1245.4,288L1284.3,288L1284.3,320L1323.2,320L1323.2,288L1362.2,288L1362.2,224L1401.1,224L1401.1,96L1440,96L1440,320L1401.1,320L1401.1,320L1362.2,320L1362.2,320L1323.2,320L1323.2,320L1284.3,320L1284.3,320L1245.4,320L1245.4,320L1206.5,320L1206.5,320L1167.6,320L1167.6,320L1128.6,320L1128.6,320L1089.7,320L1089.7,320L1050.8,320L1050.8,320L1011.9,320L1011.9,320L973,320L973,320L934.1,320L934.1,320L895.1,320L895.1,320L856.2,320L856.2,320L817.3,320L817.3,320L778.4,320L778.4,320L739.5,320L739.5,320L700.5,320L700.5,320L661.6,320L661.6,320L622.7,320L622.7,320L583.8,320L583.8,320L544.9,320L544.9,320L505.9,320L505.9,320L467,320L467,320L428.1,320L428.1,320L389.2,320L389.2,320L350.3,320L350.3,320L311.4,320L311.4,320L272.4,320L272.4,320L233.5,320L233.5,320L194.6,320L194.6,320L155.7,320L155.7,320L116.8,320L116.8,320L77.8,320L77.8,320L38.9,320L38.9,320L0,320L0,320Z"></path></svg>
                </div>

                <div className="hidden 2xl:block w-full -mx-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#1C1917" viewBox="0 0 1440 320"><path  d="M0,192L0,160L38.9,160L38.9,0L77.8,0L77.8,160L116.8,160L116.8,288L155.7,288L155.7,96L194.6,96L194.6,128L233.5,128L233.5,288L272.4,288L272.4,32L311.4,32L311.4,224L350.3,224L350.3,64L389.2,64L389.2,96L428.1,96L428.1,224L467,224L467,288L505.9,288L505.9,288L544.9,288L544.9,128L583.8,128L583.8,288L622.7,288L622.7,32L661.6,32L661.6,96L700.5,96L700.5,224L739.5,224L739.5,128L778.4,128L778.4,256L817.3,256L817.3,96L856.2,96L856.2,64L895.1,64L895.1,288L934.1,288L934.1,160L973,160L973,0L1011.9,0L1011.9,160L1050.8,160L1050.8,224L1089.7,224L1089.7,256L1128.6,256L1128.6,256L1167.6,256L1167.6,32L1206.5,32L1206.5,256L1245.4,256L1245.4,288L1284.3,288L1284.3,320L1323.2,320L1323.2,288L1362.2,288L1362.2,224L1401.1,224L1401.1,96L1440,96L1440,320L1401.1,320L1401.1,320L1362.2,320L1362.2,320L1323.2,320L1323.2,320L1284.3,320L1284.3,320L1245.4,320L1245.4,320L1206.5,320L1206.5,320L1167.6,320L1167.6,320L1128.6,320L1128.6,320L1089.7,320L1089.7,320L1050.8,320L1050.8,320L1011.9,320L1011.9,320L973,320L973,320L934.1,320L934.1,320L895.1,320L895.1,320L856.2,320L856.2,320L817.3,320L817.3,320L778.4,320L778.4,320L739.5,320L739.5,320L700.5,320L700.5,320L661.6,320L661.6,320L622.7,320L622.7,320L583.8,320L583.8,320L544.9,320L544.9,320L505.9,320L505.9,320L467,320L467,320L428.1,320L428.1,320L389.2,320L389.2,320L350.3,320L350.3,320L311.4,320L311.4,320L272.4,320L272.4,320L233.5,320L233.5,320L194.6,320L194.6,320L155.7,320L155.7,320L116.8,320L116.8,320L77.8,320L77.8,320L38.9,320L38.9,320L0,320L0,320Z"></path></svg>
                </div>
            </div>
        </section>
    );
};

export default BlockGradientUp;
