import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faPaperPlane as faPaperPlaneSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [response, setResponse] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch('https://frederik-eas.dk/php/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData),
      });
      const text = await res.text();
      setResponse(text);
    } catch (error) {
      setResponse('Beskeden blev ikke sendt.');
    }
  };

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return (
    <section id="contact" className="bg-gray-200 w-full h-full flex items-center justify-center py-16 contain-paint">
      <div className="container px-10 bg-gray-200 flex flex-col items-center justify-center relative ring">
        <i></i>
        <i></i>
        <i></i>
        <h2 className="mb-4 uppercase">Kontakt mig</h2>
        <hr className="border-t-2 border-rose-600 mx-auto w-28 mb-8" />
        <form onSubmit={handleSubmit} className="space-y-4 max-w-screen-md w-full px-4 py-6 z-10">
          <div>
            <label htmlFor="name" className="block font-quicksand font-semibold">Navn:</label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="block w-full mt-1 p-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="email" className="block font-quicksand font-semibold">Email:</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="block w-full mt-1 p-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="message" className="block font-quicksand font-semibold">Besked:</label>
            <textarea
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="block w-full mt-1 p-2 border rounded"
            ></textarea>
          </div>
          <button
            type="submit"
            className={`block mx-auto px-8 py-4 bg-rose-600 text-white font-hindmadurai rounded transition duration-200 hover:bg-rose-700 hover:-translate-y-1 uppercase ${isMobile ? '' : 'hover:bg-rose-700 hover:-translate-y-1'}`}
          >
            Send besked <FontAwesomeIcon icon={isHovered ? faPaperPlane : faPaperPlaneSolid} className="ml-2" />
          </button>
        </form>
        <div className="mt-4 min-h-[24px] w-full text-center">
          {response && <p>{response}</p>}
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
