import React from 'react';
import NavigationBar from './components/NavigationBar';
import LandingZone from './components/LandingZone';
import Introduction from './components/Introduction';
import BlockGradientUp from './components/BlockGradientUp';
import Portfolio from './components/Portfolio';
import BlockGradientDown from './components/BlockGradientDown';
import Footer from './components/Footer';
import ContactForm from './components/ContactForm';
import LandingIntroductionGradient from './components/LandingIntroductionGradient';

function App() {
  return (
    <div>
      <NavigationBar />
      <LandingZone />

      <Introduction id="aboutme" />
      <BlockGradientUp />
      <Portfolio />
      <BlockGradientDown />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
