import React, { useEffect, useRef } from 'react';

const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890!@#$%&|?></\\";
const words = ["Developer", "Student", "Programmer", "Life enjoyer" ];

const RoleTextAnimation = () => {
  const textRef = useRef(null);

  useEffect(() => {
    let interval = null;
    let letterSpeed = 50;
    let index = 0;

    const hackerEffect = (element, dataSet) => {
      clearInterval(interval);

      let iterator = 0;
      let arbitraryValues = randomArray(dataSet.value.length);

      interval = setInterval(() => {
        element.innerText = element.innerText
          .split("")
          .map((letter, index) => {
            if (iterator >= arbitraryValues[index]) {
              return dataSet.value[index];
            }
            return chars[randomNumber(chars.length)];
          })
          .join("");
        if (iterator === dataSet.value.length) {
          clearInterval(interval);
        }
        iterator += 1 / 2;
      }, letterSpeed);
    }

    const randomNumber = (length) => {
      return Math.floor(Math.random() * length);
    }

    const randomArray = (length) => {
      let randomArray = [];
      let tmp = randomNumber(length);
      while (true) {
        if (!randomArray.includes(tmp)) {
          randomArray.push(tmp);
        } else {
          tmp = randomNumber(length);
        }
        if (randomArray.length >= length) {
          break;
        }
      }
      return randomArray;
    }

    const textElement = textRef.current;
    const dataSet = { value: words[index] };
    if (textElement) {
      textElement.innerText = dataSet.value;
      hackerEffect(textElement, dataSet);

      const defaultDelay = 2000;
      const speed = letterSpeed * words.length * dataSet.value.length + defaultDelay;

      const changeWord = () => {
        index++;
        if (index >= words.length) index = 0;
        dataSet.value = words[index];
        textElement.innerText = dataSet.value;
        hackerEffect(textElement, dataSet);
        setTimeout(changeWord, speed);
      }

      setTimeout(changeWord, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <h2 ref={textRef} className="text-2xl text-white"></h2>
  );
}

export default RoleTextAnimation;
