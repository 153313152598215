import React, { useState, useRef, useEffect } from 'react';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder as faFolderSolid } from '@fortawesome/free-solid-svg-icons';
import { faFolder } from '@fortawesome/free-regular-svg-icons';


const Portfolio = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [visibleIndex, setVisibleIndex] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const projectRefs = useRef([]);

  const projects = [
    {
      title: 'Nerve signal generator',
      technologies: 'Python, Flask, JavaScript, HTML, CSS, SQLite',
      fullDescription: 'I et projektsamarbejde med virksomheden Judex udviklede min projektgruppe og jeg en Nerve Signal Generator, et teknologisk apparat designet til at efterligne karakteristikaene af et nervesignal. Programmet blev udviklet på en Raspberry Pi, der opsætter et adgangspunkt, så man kan konfigurere og tilslutte netværk, før man fysisk kobler det til elektrodiagnostisk hardware for at lave manuelle tests på den tilknyttede elektrodiagnostiske software. Generatoren gav brugerne mulighed for at modulere intensiteten af nervesignalet, introducere tidsmæssige forsinkelser og inkorporere hvid støj, hvilket muliggjorde en nuanceret udforskning af signaldynamik. Brugergrænsefladen blev konstrueret ved hjælp af en kombination af Python til backend-funktionalitet, JavaScript til dynamisk interaktivitet, HTML/CSS til præsentation og en SQLite-database. De genererede nervesignaler blev visualiseret og manipuleret gennem et JavaScript-baseret diagram, hvilket faciliterede brugerinteraktion og tilpasning gennem grafisk manipulation af datapunkter inden for diagrammets grænseflade.',
      imageUrl: './images/nsg.png',
      charLimit: 130,
    },
    {
      title: 'Fra Desktop app til Webapp',
      technologies: 'C#, ASP.NET Core, Angular, MS SQL Server',
      fullDescription: 'I samarbejde med Treat Systems ApS og Aalborg Universitet (AAU) arbejdede min projektgruppe og jeg på at konvertere en eksisterende prototype fra en desktop-løsning til en webbaseret løsning. Dette beslutningsstøttende værktøj, kaldet GlucoSafe, er designet til at hjælpe med at styre ernæring og blodsukkerniveau hos patienter på intensivafdelinger. En avanceret algoritme, udviklet af en gruppe forskere, herunder Ulrike Sabine Pielmeier, lektor og PhD i sundhedsvidenskab og teknologi ved AAU, anvender patientdata, ernæring og insulinbehandlinger til at forudsige blodsukkerniveauet og give anbefalinger til optimal behandling. \n  Projektets hovedmål var at omdanne GlucoSafeDesktop til en webbaseret løsning. Dette involverede en omskrivning af algoritmen fra Java til C# samt implementering af et nyt webbaseret system med samme funktionalitet. Backend blev udviklet ved hjælp af ASP.NET Core til API’et, mens frontend blev udviklet i Angular for at skabe en dynamisk og interaktiv brugergrænseflade. Systemets data blev gemt i en Microsoft SQL Server, og den avancerede algoritme blev oversat.',
      imageUrl: './images/gs-udklip.png',
      charLimit: 130,
    },
    {
      title: 'Hjemmeside Portefølje',
      technologies: 'React, HTML, Tailwind CSS, JavaScript, PHP',
      fullDescription: 'Bla bla bla...',
      imageUrl: './images/hjemport.png',
      charLimit: 130,
    },
  ];

  const openModal = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      const handleScroll = () => {
        const middleY = window.innerHeight / 2;
        let foundVisible = false;

        projectRefs.current.forEach((ref, index) => {
          const rect = ref.getBoundingClientRect();
          if (rect.top <= middleY && rect.bottom >= middleY) {
            setVisibleIndex(index);
            foundVisible = true;
          }
        });

        if (!foundVisible) {
          setVisibleIndex(null);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [windowWidth]);

  return (
    <section id="portfolio" className="relative bg-stone-900 pt-16 py-24">
      <div className="container mx-auto px-8 text-light">
        <h2 className="mx-auto mb-4 flex justify-center">Min Portefølje</h2>
        <hr className="border-t-2 border-yellow-600 mx-auto w-28 mb-6" />
        <p className="mb-8 flex justify-center paragraph">Her er nogle af de større projekter, som jeg har arbejdet på ... </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
          {projects.map((project, index) => (
            <div
              key={index}
              ref={(el) => (projectRefs.current[index] = el)}
              className={`bg-stone-800 p-6 rounded-lg shadow-lg cursor-pointer transform transition-transform duration-300 ${
                windowWidth < 768 && visibleIndex === index ? 'scale-105' : ''
              } ${windowWidth >= 768 ? 'hover:scale-105' : ''}`}
              onClick={() => openModal(project)}
            >
              <div className="flex justify-between items-center my-4 ml-4 mb-8">
              <FontAwesomeIcon icon={faFolder} className='text-5xl text-yellow-600' />
              <p className='technology'>
                {project.technologies}
              </p>
              </div>
              <h3 className="">{project.title}</h3>
              <p className="mt-2">
                {project.fullDescription.length > project.charLimit
                  ? `${project.fullDescription.substring(0, project.charLimit)}...`
                  : project.fullDescription}
              </p>
              <img src={project.imageUrl} alt={project.title} className="mt-12 w-full rounded-2xl" />
            </div>
          ))}
        </div>
      </div>
      {selectedProject && <Modal project={selectedProject} closeModal={closeModal} />}
    </section>
  );
};

export default Portfolio;
