import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const NavigationBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      setIsScrolled(scrollTop >= windowHeight);
      if (isOpen) {
        closeMenu();
      }
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth >= 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    if (isOpen) {
      closeMenu();
    } else {
      setIsAnimating(true);
      setIsOpen(true);
    }
  };

  const closeMenu = () => {
    setIsAnimating(true);
    setIsOpen(false);
    setTimeout(() => {
      setIsAnimating(false);
    }, 300); // Match the duration of the popUp animation
  };

  return (
    <header
      className={`text-white fixed w-full z-10 transition-colors duration-300 ${
        isScrolled ? 'bg-neutral-800' : 'bg-gradient-to-b from-neutral-800 to-transparent'
      }`}
    >
      <nav className="container font-quicksand mx-auto flex items-center justify-between py-4 px-4 max-w-8xl">
        <div className="text-sm mr-4">Frederik EAS
        <hr className="border-t-2 border-rose-600 mx-auto w-12" />
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className=" mr-4 p-2">
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} className="text-2xl" />
          </button>
        </div>
        <ul className={`hidden md:flex items-center`}>
          <li className="flex mx-auto items-center">
            <a
              href="#aboutme"
              className="text-white mx-2 hover:bg-neutral-700 hover:bg-opacity-50 px-6 py-2 rounded transition duration-200"
            >
              OM MIG
            </a>
            <div className="h-6 border-l border-gray-600 mx-2"></div>
          </li>
          <li className="flex items-center">
            <a
              href="#portfolio"
              className="text-white mx-2 hover:bg-neutral-700 hover:bg-opacity-50 px-6 py-2 rounded transition duration-200"
            >
              PORTEFØLJE
            </a>
            <div className="h-6 border-l border-gray-600 mx-2"></div>
          </li>
          <li className="flex items-center">
            <a
              href="#contact"
              className="text-white mx-2 hover:bg-neutral-700 hover:bg-opacity-50 px-6 py-2 rounded transition duration-200"
            >
              KONTAKT
            </a>
          </li>
        </ul>
      </nav>
      {isOpen && windowWidth < 768 && (
        <div className={`absolute top-full right-0 mt-2 bg-neutral-800 rounded-lg shadow-lg py-2 px-2 w-full md:w-48 ${isAnimating ? 'pop-down' : ''}`}>
          <ul className="space-y-2 font-quicksand">

            <li className="border-b border-neutral-600 py-2 flex justify-between items-center hover:bg-neutral-700 hover:bg-opacity-50 p-2 rounded transition duration-200">
              <a
                href="#aboutme"
                className="text-white w-full"
                onClick={toggleMenu}
              >
                OM MIG
              </a>
              <FontAwesomeIcon icon={faChevronRight} className="text-white" />
            </li>

            <li className="border-b border-neutral-600 py-2 flex justify-between items-center hover:bg-neutral-700 hover:bg-opacity-50 p-2 rounded transition duration-200 ">
              <a
                href="#portfolio"
                className="text-white w-full"
                onClick={toggleMenu}
              >
                PORTEFØLJE
              </a>
              <FontAwesomeIcon icon={faChevronRight} className="text-white" />
            </li>

            <li className="py-2 flex justify-between items-center hover:bg-neutral-700 hover:bg-opacity-50 p-2 rounded transition duration-200">
              <a
                href="#contact"
                className="text-white w-full"
                onClick={toggleMenu}
              >
                KONTAKT
              </a>
              <FontAwesomeIcon icon={faChevronRight} className="text-white" />
            </li>

          </ul>
        </div>
      )}
      {!isOpen && isAnimating && (
        <div className={`absolute top-full right-0 mt-2 bg-neutral-800 rounded-lg shadow-lg py-2 px-2 w-full md:w-48 pop-up`}>
          <ul className="space-y-2 font-quicksand">
            <li className="border-b border-neutral-600 py-2 flex justify-between items-center hover:bg-neutral-700 hover:bg-opacity-50 p-2 rounded transition duration-200">
              <a
                href="#aboutme"
                className="text-white w-full"
                onClick={toggleMenu}
              >
                OM MIG
              </a>
              <FontAwesomeIcon icon={faChevronRight} className="text-white" />
            </li>
            <li className="border-b border-neutral-600 py-2 flex justify-between items-center hover:bg-neutral-700 hover:bg-opacity-50 p-2 rounded transition duration-200">
              <a
                href="#portfolio"
                className="text-white w-full"
                onClick={toggleMenu}
              >
                PORTEFØLJE
              </a>
              <FontAwesomeIcon icon={faChevronRight} className="text-white" />
            </li>
            <li className="py-2 flex justify-between items-center hover:bg-neutral-700 hover:bg-opacity-50 p-2 rounded transition duration-200">
              <a
                href="#contact"
                className="text-white w-full"
                onClick={toggleMenu}
              >
                KONTAKT
              </a>
              <FontAwesomeIcon icon={faChevronRight} className="text-white" />
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default NavigationBar;
