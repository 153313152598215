import React from 'react';

const Introduction = () => {
  return (
    <section id="aboutme" className="flex-1 bg-gray-200 flex items-center justify-center">
      <div className="container mx-10 py-24 flex flex-col md:flex-row items-center justify-between">
        {/* Content */}
        <div className="text-lg py-8 max-w-screen-lg mb-8 md:mb-0 bg-neutral-100 rounded-lg shadow-lg">
          {/* Heading */}
          <h2 className="mb-4 text-center ">Lidt om mig</h2>
          <hr className="border-t-2 border-rose-500 w-28 mx-auto mb-8" />
          {/* Text */}
          <div className="mx-10 justify-center paragraph">
            <p className="">
              Jeg er en <b className='font-bold'>engageret</b> IT studerende med en stor <b className='font-bold'>passion</b> for softwareudvikling og 
              teknologisk innovation. 
            </p>
            <p>
              I min <b className='font-bold'>fritid</b> nørder jeg madlavning, 
              små kodeprojekter og spiller 
              bræt- og computerspil - hvis jeg 
              altså ikke befinder mig i et koldt 
              bad eller i den nærmeste sauna.
            </p>
            <p>
              Endvidere har jeg en naturlig 
              <b className='font-bold'> nysgerrighed</b> for alt jeg laver, og 
              brænder for kontinuerlig <b className='font-bold'>læring </b>
              og <b className='font-bold'>udvikling</b>. Både på et 
              personligt og proffessionelt plan.
            </p>
            <p>
              Som en del af et team bestræber jeg mig 
              altid efter at levere kvalitet gennem 
              min detaljeorienteret og strukturerede tilgang. 
              Desuden bidrager jeg med god energi og massere af smil.
            </p>
            <br></br>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
            </p>

          </div>
        </div>
        {/* Circular image */}
        <div className="flex-shrink mx-10 min-w-fit pt-14">
            <img
              src="./images/me.jpg" // Replace with your image URL
              alt="Frederik Emil Albin Schmidt"
              className="w-64 h-64 rounded-full object-cover shadow-lg">

            </img>
        </div>
      </div>
    </section>
  );
}

export default Introduction;
