import React from 'react';

const BlockGradientDown= () => {
    return (
        <section>
            {/* Wave pattern */}
            <div className="bg-gray-200 -m-1 flex flex-col md:flex-row mx-auto">
                <div className="w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#1C1917" fill-opacity="1" d="M0,160L0,288L36.9,288L36.9,96L73.8,96L73.8,224L110.8,224L110.8,288L147.7,288L147.7,32L184.6,32L184.6,64L221.5,64L221.5,224L258.5,224L258.5,32L295.4,32L295.4,192L332.3,192L332.3,64L369.2,64L369.2,224L406.2,224L406.2,160L443.1,160L443.1,320L480,320L480,256L516.9,256L516.9,256L553.8,256L553.8,96L590.8,96L590.8,160L627.7,160L627.7,64L664.6,64L664.6,192L701.5,192L701.5,64L738.5,64L738.5,192L775.4,192L775.4,96L812.3,96L812.3,160L849.2,160L849.2,128L886.2,128L886.2,64L923.1,64L923.1,64L960,64L960,96L996.9,96L996.9,0L1033.8,0L1033.8,96L1070.8,96L1070.8,160L1107.7,160L1107.7,96L1144.6,96L1144.6,128L1181.5,128L1181.5,320L1218.5,320L1218.5,32L1255.4,32L1255.4,192L1292.3,192L1292.3,224L1329.2,224L1329.2,64L1366.2,64L1366.2,32L1403.1,32L1403.1,96L1440,96L1440,0L1403.1,0L1403.1,0L1366.2,0L1366.2,0L1329.2,0L1329.2,0L1292.3,0L1292.3,0L1255.4,0L1255.4,0L1218.5,0L1218.5,0L1181.5,0L1181.5,0L1144.6,0L1144.6,0L1107.7,0L1107.7,0L1070.8,0L1070.8,0L1033.8,0L1033.8,0L996.9,0L996.9,0L960,0L960,0L923.1,0L923.1,0L886.2,0L886.2,0L849.2,0L849.2,0L812.3,0L812.3,0L775.4,0L775.4,0L738.5,0L738.5,0L701.5,0L701.5,0L664.6,0L664.6,0L627.7,0L627.7,0L590.8,0L590.8,0L553.8,0L553.8,0L516.9,0L516.9,0L480,0L480,0L443.1,0L443.1,0L406.2,0L406.2,0L369.2,0L369.2,0L332.3,0L332.3,0L295.4,0L295.4,0L258.5,0L258.5,0L221.5,0L221.5,0L184.6,0L184.6,0L147.7,0L147.7,0L110.8,0L110.8,0L73.8,0L73.8,0L36.9,0L36.9,0L0,0L0,0Z"></path></svg>
                </div>

                <div className="hidden 2xl:block w-full -mx-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#1C1917" fill-opacity="1" d="M0,160L0,288L36.9,288L36.9,96L73.8,96L73.8,224L110.8,224L110.8,288L147.7,288L147.7,32L184.6,32L184.6,64L221.5,64L221.5,224L258.5,224L258.5,32L295.4,32L295.4,192L332.3,192L332.3,64L369.2,64L369.2,224L406.2,224L406.2,160L443.1,160L443.1,320L480,320L480,256L516.9,256L516.9,256L553.8,256L553.8,96L590.8,96L590.8,160L627.7,160L627.7,64L664.6,64L664.6,192L701.5,192L701.5,64L738.5,64L738.5,192L775.4,192L775.4,96L812.3,96L812.3,160L849.2,160L849.2,128L886.2,128L886.2,64L923.1,64L923.1,64L960,64L960,96L996.9,96L996.9,0L1033.8,0L1033.8,96L1070.8,96L1070.8,160L1107.7,160L1107.7,96L1144.6,96L1144.6,128L1181.5,128L1181.5,320L1218.5,320L1218.5,32L1255.4,32L1255.4,192L1292.3,192L1292.3,224L1329.2,224L1329.2,64L1366.2,64L1366.2,32L1403.1,32L1403.1,96L1440,96L1440,0L1403.1,0L1403.1,0L1366.2,0L1366.2,0L1329.2,0L1329.2,0L1292.3,0L1292.3,0L1255.4,0L1255.4,0L1218.5,0L1218.5,0L1181.5,0L1181.5,0L1144.6,0L1144.6,0L1107.7,0L1107.7,0L1070.8,0L1070.8,0L1033.8,0L1033.8,0L996.9,0L996.9,0L960,0L960,0L923.1,0L923.1,0L886.2,0L886.2,0L849.2,0L849.2,0L812.3,0L812.3,0L775.4,0L775.4,0L738.5,0L738.5,0L701.5,0L701.5,0L664.6,0L664.6,0L627.7,0L627.7,0L590.8,0L590.8,0L553.8,0L553.8,0L516.9,0L516.9,0L480,0L480,0L443.1,0L443.1,0L406.2,0L406.2,0L369.2,0L369.2,0L332.3,0L332.3,0L295.4,0L295.4,0L258.5,0L258.5,0L221.5,0L221.5,0L184.6,0L184.6,0L147.7,0L147.7,0L110.8,0L110.8,0L73.8,0L73.8,0L36.9,0L36.9,0L0,0L0,0Z"></path></svg>
                </div>
            </div>

        </section>
    );
};

export default BlockGradientDown;